import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';


export interface Globals {
    clock: THREE.Clock;
    frameNumber: number;
    listener: THREE.AudioListener;
    deltaTime: number;
    time: number;
    stats: Stats;
}

export const globals: Globals = {
    clock: new THREE.Clock(),
    deltaTime: 0,
    time: 0,
    frameNumber: 0,
    listener: new THREE.AudioListener(),
    stats: new Stats(),
};
