import Decimal from 'decimal.js';
import { differenceInMilliseconds } from 'date-fns';

export class FarmRewardCalculateService {
    linearCalculateReward(
        startTime: Date,
        currentTime: Date,
        initialSpeed: number,
        fixedIntervalHours: number,
        decayIntervalHours: number,
        decayRate: number,
        minSpeed: number,
        rewardPerHour: number,
    ): { reward: Decimal; currentSpeed: Decimal } {
        let rewards = 0;
        let currentSpeed;
        const totalTimeHours = differenceInMilliseconds(currentTime, startTime) / 1000 / 3600;
        if (totalTimeHours <= fixedIntervalHours) {
            rewards = totalTimeHours * rewardPerHour * initialSpeed;
            currentSpeed = initialSpeed;
        } else {
            const fixedReward = fixedIntervalHours * rewardPerHour * initialSpeed;
            const passedTimeAfterFixed = differenceInMilliseconds(currentTime, startTime) / 1000 / 3600 - fixedIntervalHours;
            currentSpeed = initialSpeed * (1 - decayRate * passedTimeAfterFixed / decayIntervalHours);
            if (currentSpeed >= minSpeed) {
                const decayReward = rewardPerHour * passedTimeAfterFixed * (initialSpeed + currentSpeed) / 2;
                rewards = fixedReward + decayReward;
            } else {
                // add third interval - fixed min speed
                const maxDecayInterval = (initialSpeed - minSpeed) / decayRate;
                currentSpeed = minSpeed;
                const decayReward = rewardPerHour * maxDecayInterval * (initialSpeed + currentSpeed) / 2;
                const minSpeedInterval = passedTimeAfterFixed - maxDecayInterval;
                const minSpeedReward = minSpeedInterval * rewardPerHour * minSpeed;
                rewards = fixedReward + decayReward + minSpeedReward;
            }
        }

        return {
            reward: new Decimal(Math.max(0, rewards)),
            currentSpeed: new Decimal(currentSpeed),
        };
    }
}
