import EventEmitter from 'eventemitter3';
const eventEmitter = new EventEmitter();

const Emitter = {
    on: (event: string, fn: any, context: any) => eventEmitter.on(event, fn, context),
    once: (event: string, fn: any) => eventEmitter.once(event, fn),
    off: (event: string, fn: any, context: any) => eventEmitter.off(event, fn, context),
    emit: (event: string, payload: any) => eventEmitter.emit(event, payload),
};
Object.freeze(Emitter);

export const EVENTS = {
    // TAP: 'tap',
    MOVE: 'move',
    DOWN: 'down',
    UP: 'up',
};
export default Emitter;
