import { useCallback, useEffect, useRef, useState } from 'react';
import { getServerTime } from '../modules/utils';

export type Countdown = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    isEnded?: boolean;
};

export const useCountdown = (endDate: string | undefined, serverTimeDiffMs: number | undefined) => {
    const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
    const [countdown, setCountdown] = useState<Countdown | null>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isEnded: false,
    });

    const calculateCountdown = useCallback(() => {
        if (!endDate) return;

        const currentDate = getServerTime(serverTimeDiffMs).getTime();
        const delta = new Date(endDate).getTime() - currentDate;

        if (delta < 0) {
            if (intervalId?.current) {
                clearInterval(intervalId.current);
            }

            setCountdown((prevState) => {
                if (!prevState) return null;

                return { ...prevState, isEnded: true };
            });

            return;
        }

        const days = Math.floor(delta / (1000 * 60 * 60 * 24));
        const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((delta % (1000 * 60)) / 1000);

        setCountdown({
            days,
            hours,
            minutes,
            seconds,
            isEnded: false,
        });
    }, [endDate, serverTimeDiffMs]);

    useEffect(() => {
        if (!endDate) return undefined;

        calculateCountdown();

        intervalId.current = setInterval(calculateCountdown, 1000);

        return () => {
            if (intervalId?.current) {
                clearInterval(intervalId.current);
            }
        };
    }, [endDate, calculateCountdown]);

    return countdown;
};
