import { retrieveLaunchParams } from '@tma.js/sdk';
import axios, { AxiosError } from 'axios';
import { InitDataDto, ReferralInfoDto, ReferralInfoUsers, TasksResponseDTO, UserDto } from './dto';
import { LaunchParams } from '@tma.js/sdk/dist/dts/launch-params/types';
import ReactGA from 'react-ga4';

const API_URL = process.env.REACT_APP_API_URL;
export const httpClient = axios.create();
let signInCalled = false;

function getRawInitData(): LaunchParams {
    try {
        return retrieveLaunchParams();
    } catch (e) {
        const debugInitData = process.env.REACT_APP_DEBUG_INIT_DATA;
        if (debugInitData) {
            return {
                initDataRaw: debugInitData,
            } as LaunchParams;
        } else {
            throw e;
        }
    }
}

async function getGaClientId() {
    return new Promise((res) => {
        try {
            ReactGA.ga((tracker: any) => {
                console.log('tracker', tracker);
                let clientId = tracker.get('clientId');
                res(clientId);
            });
        } catch (e) {
            console.error(e);
            res('');
        }
    });
}

export async function doSignIn(): Promise<InitDataDto | null> {
    if (signInCalled) {
        return null;
    }
    const gaClientId = await getGaClientId();
    console.log('gaClientId', gaClientId);
    console.log('doSignIn');
    signInCalled = true;
    const launchParams = getRawInitData();
    const response = await httpClient.post(`${API_URL}/api/v1/auth/sign-in`, {
        initData: launchParams.initDataRaw,
        refCode: launchParams.startParam,
        gaTag: gaClientId,
    });
    let initData = response.data as InitDataDto;
    const token = initData.token;

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    ReactGA.set({
        telegramId: initData.user.telegramId,
        username: initData.user.username,
        status: initData.user.status,
        isPremium: initData.user.isPremium,
        createdAt: initData.user.createdAt,
        refCode: initData.user.refCode,
    });

    console.log('initData', initData);
    return initData;
}

export async function getUserInfo() {
    const response = await httpClient.get(`${API_URL}/api/v1/user/me`);
    return response.data;
}

export async function startFarmSession(): Promise<UserDto | null> {
    const response = await httpClient.post(`${API_URL}/api/v1/farm/start`);
    if (response.status === 200) {
        return response.data;
    } else {
        return null;
    }
}

export async function claimFarmSession(): Promise<UserDto | null> {
    const response = await httpClient.post(`${API_URL}/api/v1/farm/claim`);
    if (response.status === 200) {
        return response.data;
    } else {
        return null;
    }
}

export async function makeTapRaw(speed: number): Promise<UserDto | boolean> {
    try {
        let axiosResponse = await httpClient.post(`${API_URL}/api/v1/farm/tap`, { speed: speed });
        return axiosResponse.data;
    } catch (e) {
        console.log(e);
        let e1 = e as AxiosError;
        if (e1?.response?.status === 409 || e1?.response?.status === 404) {
            return false;
        }
        if (e1?.response?.status === 422) {
            return true;
        } else {
            throw e;
        }
    }
}

export async function makeTap(speed: number) {
    return await makeTapRaw(speed);
}

export async function getReferralInfo(): Promise<ReferralInfoDto | null> {
    const response = await httpClient.get(`${API_URL}/api/v1/referral/me`);
    return response.data;
}

export async function claimReferral(): Promise<UserDto | null> {
    const response = await httpClient.post(`${API_URL}/api/v1/referrer/claim`);

    return response.data;
}

export async function getMyReferrersInfo(): Promise<ReferralInfoDto> {
    const response = await httpClient.get(`${API_URL}/api/v1/referrer/me`);
    return response.data;
}

export async function getMyReferralsInfo({
    page,
    pageSize,
}: {
    page: number;
    pageSize: number;
}): Promise<ReferralInfoUsers> {
    const response = await httpClient.get(`${API_URL}/api/v1/referrer/referrals`, {
        params: { page, pageSize },
    });
    return response.data;
}

export async function createUser(): Promise<UserDto | null> {
    const response = await httpClient.post(`${API_URL}/api/v1/user/create`);
    if (response.status === 200) {
        return response.data;
    } else {
        return null;
    }
}

export async function getUserTasks(): Promise<TasksResponseDTO> {
    try {
        const response = await httpClient.get(`${API_URL}/api/v1/tasks`);

        return response.data;
    } catch (e) {
        throw e;
    }
}

export async function startTask(id: string): Promise<TasksResponseDTO> {
    try {
        const response = await httpClient.post(`${API_URL}/api/v1/tasks/start`, {
            taskId: id,
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}

export async function checkTask(id: string): Promise<TasksResponseDTO> {
    try {
        const response = await httpClient.post(`${API_URL}/api/v1/tasks/check`, {
            taskId: id,
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}

export async function claimTask(id: string): Promise<TasksResponseDTO> {
    try {
        const response = await httpClient.post(`${API_URL}/api/v1/tasks/claim`, {
            taskId: id,
        });

        return response.data;
    } catch (e) {
        throw e;
    }
}
