import { clamp, generateUUID } from 'three/src/math/MathUtils';
import { getTime } from './time';

export interface ICoroutine {
    stop(): void;
    get isAlive(): boolean;
}
export interface IIteratorCoroutine extends ICoroutine {
    abort(): void;
}
export type GeneratorFunction = () => Generator<null | undefined, void, unknown>;

export abstract class CoroutineManager {
    private static generators = new Map<
        string,
        { isAlive: boolean; generator: ReturnType<GeneratorFunction> }
    >();

    public static StartCoroutine(coroutine: GeneratorFunction): ICoroutine {
        const generator = {
            isAlive: true,
            generator: coroutine(),
        };
        CoroutineManager.generators.set(generateUUID(), generator);
        return {
            stop() {
                generator.isAlive = false;
            },
            get isAlive() {
                return generator.isAlive;
            },
        };
    }

    public static StartIterate(
        duration: number,
        iterate: (t: number) => void,
        onFinish?: () => void,
    ): IIteratorCoroutine {
        const start = getTime();
        let isRunning = true;
        iterate(0);
        const coroutine = CoroutineManager.StartCoroutine(function* () {
            while (isRunning) {
                const now = getTime();
                const t = clamp((now - start) / duration, 0, 1);
                iterate(t);
                if (start + duration <= now) {
                    isRunning = false;
                } else {
                    yield;
                }
            }
            onFinish && onFinish();
        });
        return {
            get isAlive() {
                return coroutine.isAlive ? isRunning : false;
            },
            stop() {
                isRunning = false;
            },
            abort: coroutine.stop,
        };
    }

    public static Next() {
        if (CoroutineManager.generators.size <= 0) {
            return;
        }

        const excludeCoroutine: string[] = [];
        CoroutineManager.generators.forEach((v, k) => {
            if (!v.isAlive) {
                excludeCoroutine.push(k);
                return;
            }

            const iterator = v.generator.next();
            if (iterator.done) {
                v.isAlive = false;
                excludeCoroutine.push(k);
            }
        });
        excludeCoroutine.forEach((k) => {
            CoroutineManager.generators.delete(k);
        });
    }
}

export function isIteratorCoroutine(v: ICoroutine | IIteratorCoroutine): v is IIteratorCoroutine {
    return Object.keys(v).includes('abort');
}
