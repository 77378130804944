import { globals } from './globals';
import { clamp } from './utils';

export const TARGET_LOW_FPS = 25;
export const GOOD_FPS = 50;
const dynamicQuality = false;
const INCREASE_STEP_TIME = 0.2;
const DECREASE_STEP_TIME = 0.1;
const MIN_QUALITY = 0.5;
const MAX_QUALITY = 0.6;

export class QualityDetector {
    private qdFrameCount = 0;
    private qdTime = 0;
    private decreaseCount = 0;
    quality: number = 0.5; // 0..1

    public update() {
        this.qdFrameCount++;
        this.qdTime += globals.deltaTime;
        if (!dynamicQuality) {
            return;
        }
        const avgFps = this.qdFrameCount / this.qdTime;
        console.log('qd', this.qdTime, this.qdFrameCount, avgFps);
        if (this.qdTime > DECREASE_STEP_TIME) {
            if (avgFps < TARGET_LOW_FPS) {
                this.decrease(avgFps);
                this.qdTime = 0;
                this.qdFrameCount = 0;
                this.decreaseCount++;
            }
        }
        if (this.qdTime > INCREASE_STEP_TIME) {
            if (avgFps > GOOD_FPS) {
                this.increase(avgFps);
                this.qdTime = 0;
                this.qdFrameCount = 0;
            }
        }
    }

    private decrease(avgFps: number) {
        this.quality = clamp(this.quality - 0.05, MIN_QUALITY, MAX_QUALITY);
        console.log(`Decrease quality to ${this.quality} FPS ${avgFps}`);
    }

    private increase(avgFps: number) {
        this.quality = clamp(this.quality + 0.05, MIN_QUALITY, MAX_QUALITY);
        console.log(`Increase quality to ${this.quality} FPS ${avgFps}`);
    }
}

export const qualityDetector = new QualityDetector();
