import { Button } from 'antd';
import React from 'react';

type Props = {
    onCreateAccount: VoidFunction;
};

export const FirstScreen: React.FC<Props> = ({ onCreateAccount }) => {
    return (
        <div className="flex h-full flex-col justify-between">
            <div className="flex pt-5 px-2 w-full flex-col items-center justify-items-start flex-grow">
                <img alt="roach_team" src="/assets/roach_team.png" className="w-full z-[1]" />
                <div className="relative w-full">
                    <div className="mt-[-82px] bg-[linear-gradient(0deg,_#000_0%,_rgba(0,_0,_0,_0.00)_100%)] h-[73px] absolute w-full z-[2] top-[-6px]" />
                </div>
                <div className="relative relative  w-full flex justify-center">
                    <img alt="tma" src="/assets/logo.svg" className="w-[80px] h-[80px] z-[3]" />
                    <img
                        alt="blur"
                        src="/assets/blur.svg"
                        className="top-[-50px] right-0 left-0 bottom-0 absolute w-full"
                    />
                </div>
                <div className="flex gap-3 flex-col px-4.5  max-w-[325px] mt-3 relative">
                    <p className="text-white text-2xl text-center font-bold">
                        Welcome to
                        <p>Roach Racing Club</p>
                    </p>
                    <span className="text-base text-white font-mono text-center">
                        Farm MTGN and hatch <p>bio-mechanical roaches.</p>{' '}
                        <p>Compete with your friends to </p> earn even more!
                    </span>
                </div>
            </div>
            <div className="flex pb-7 px-4">
                <Button
                    onClick={onCreateAccount}
                    type="primary"
                    className="!text-black !text-[15px] font-semibold font-mono w-full !h-[49px]"
                >
                    Create account
                </Button>
            </div>
        </div>
    );
};
