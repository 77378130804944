import { memo } from 'react';

export const SvgGlass = memo(() => {
    return (
        <svg
            width="286"
            height="286"
            viewBox="0 0 286 286"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2907_19284)">
                <g filter="url(#filter0_f_2907_19284)">
                    <path
                        d="M284 142.513C284 184.144 266.826 195.551 238.301 221.607C212.897 244.812 217.594 197.38 217.594 197.38C217.594 197.38 230.608 137.614 209.739 110.804C179.204 71.5765 107.442 153.662 82.2821 110.804C57.4704 68.5378 62.3188 0 141.19 0C220.062 0 284 63.8051 284 142.513Z"
                        fill="url(#paint0_linear_2907_19284)"
                        fillOpacity="0.08"
                    />
                </g>
                <g filter="url(#filter1_f_2907_19284)">
                    <path
                        d="M250.367 205.822C250.367 205.822 237.316 223.652 231.1 217.832C226.142 213.191 239.55 198.339 239.55 198.339C239.55 198.339 253.828 171.895 259.218 177.882C263.252 182.362 250.367 205.822 250.367 205.822Z"
                        fill="white"
                        fillOpacity="0.08"
                    />
                </g>
                <g filter="url(#filter2_f_2907_19284)">
                    <path
                        d="M100.77 274.006C81.138 265.545 34.8251 236.691 44.2506 226.283C51.7679 217.981 88.8051 252.421 107.27 259.437C127.894 267.272 170.991 236.03 178.293 256.927C186.362 280.02 123.162 283.657 100.77 274.006Z"
                        fill="white"
                        fillOpacity="0.08"
                    />
                </g>
                <g filter="url(#filter3_f_2907_19284)">
                    <path
                        d="M-1 140.931C-1 160.072 2.79759 178.33 9.68382 195C30.0952 149.468 70.8519 125.735 122.309 99.6678C172.739 74.121 228.462 55.5589 263 65.1632C227.032 27.4572 203.508 14.0009 157.336 12.1614C119.446 10.6519 95.8255 19.6821 64.7647 41.3302C26.4579 68.0284 9.7225 85.4391 -1 140.931Z"
                        fill="white"
                        fillOpacity="0.05"
                    />
                </g>
                <g filter="url(#filter4_f_2907_19284)">
                    <path
                        d="M-1 140.931C-1 160.072 2.79759 178.33 9.68382 195C30.0952 149.468 70.8519 125.735 122.309 99.6678C172.739 74.121 228.462 55.5589 263 65.1632C227.032 27.4572 203.508 14.0009 157.336 12.1614C119.446 10.6519 95.8255 19.6821 64.7647 41.3302C26.4579 68.0284 9.7225 85.4391 -1 140.931Z"
                        fill="url(#paint1_linear_2907_19284)"
                        fillOpacity="0.1"
                    />
                </g>
                <g filter="url(#filter5_f_2907_19284)">
                    <path
                        d="M16.3253 133.7C12.5863 146.702 23.5385 168.368 11.3743 167.995C-0.678934 167.625 1.953 153.086 2.95632 138.174C4.19334 119.802 20.7817 104.377 20.7817 104.377C20.7817 104.377 53.3249 71.3635 54.9473 73.0635C56.4808 74.6704 24.0908 106.696 16.3253 133.7Z"
                        fill="white"
                        fillOpacity="0.08"
                    />
                </g>
                <circle cx="143" cy="143" r="143" fill="white" fillOpacity="0.05" />
            </g>
            <defs>
                <filter
                    id="filter0_f_2907_19284"
                    x="53"
                    y="-16"
                    width="247"
                    height="260"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <filter
                    id="filter1_f_2907_19284"
                    x="218"
                    y="165"
                    width="54"
                    height="66"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <filter
                    id="filter2_f_2907_19284"
                    x="31"
                    y="213"
                    width="160"
                    height="78"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <filter
                    id="filter3_f_2907_19284"
                    x="-5"
                    y="8"
                    width="272"
                    height="191"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <filter
                    id="filter4_f_2907_19284"
                    x="-3"
                    y="10"
                    width="268"
                    height="187"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <filter
                    id="filter5_f_2907_19284"
                    x="-14"
                    y="57"
                    width="85"
                    height="127"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_2907_19284" />
                </filter>
                <linearGradient
                    id="paint0_linear_2907_19284"
                    x1="284"
                    y1="114"
                    x2="69"
                    y2="114"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2907_19284"
                    x1="196.367"
                    y1="15.7641"
                    x2="108.155"
                    y2="164.648"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.0976557" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_2907_19284">
                    <rect width="286" height="286" rx="143" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
