'use client';

import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    useContext,
    useMemo,
    useState,
} from 'react';

type ProviderProps = {};

type ContextValue = {
    setError: Dispatch<React.SetStateAction<string | null>>;
    error: string | null;
};

const Context = createContext<ContextValue>({
    error: null,
    setError: () => {},
});

export const ErrorContextProvider: React.FC<PropsWithChildren<ProviderProps>> = ({ children }) => {
    const [error, setError] = useState<string | null>('');

    const contextValue = useMemo(
        () => ({
            setError,
            error,
        }),
        [error, setError],
    );

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useErrorContext = () => useContext(Context);
