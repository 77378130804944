import { ConfigDto } from './dto';
import { EggState } from '../pages/main/Egg/EggModel';

export class Store {
    sessionFarmedCoins: string | null = null;
    state: EggState = EggState.NONE;
    tapProgress: number | null = null;
    tapCount: number = 0;
}

export class Config {
    config: ConfigDto | null = null;
    serverTimeDiffMs: number | undefined = undefined;
}
