import { Button } from 'antd';
import { ReferralInfoDto, ReferralInfoUsers, UserDto } from '../../../../modules/dto';
import { useHapticFeedback, useUtils } from '@tma.js/sdk-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { claimReferral, getMyReferralsInfo, getMyReferrersInfo } from '../../../../modules/api';
import { RefList } from './RefList';
import * as clipboard from 'clipboard-polyfill';
import { toast } from 'react-toastify';
import { useHandleError } from '../../../../hooks/useHandleError';
import { AxiosError } from 'axios';

interface FriendsProps {
    user?: UserDto | null;
}

const PAGE_SIZE = 10;

export const Friends = ({ user }: FriendsProps) => {
    const [page, setPage] = useState(1);
    const haptic = useHapticFeedback();
    const { handleError } = useHandleError();

    const [referralInfo, setReferralInfo] = useState<ReferralInfoDto | null>(null);
    const [referrals, setReferrals] = useState<ReferralInfoUsers | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const haveFriends = !!referrals?.result?.length;
    const utils = useUtils();

    const onClaim = useCallback(async () => {
        setTimeout(() => {
            try {
                haptic.impactOccurred('medium');
            } catch (error) {
                console.error('Error:', error);
            }
        }, 0);

        try {
            await claimReferral();

            const result = await getMyReferrersInfo();

            setReferralInfo(result);
        } catch (e) {
            handleError(e as AxiosError);
        }

        // eslint-disable-next-line
    }, [haptic]);

    const updateInfo = useCallback(async () => {
        setLoading(true);
        try {
            const [referrerInfo, referralsInfo] = await Promise.all([
                getMyReferrersInfo(),
                getMyReferralsInfo({ page, pageSize: PAGE_SIZE }),
            ]);
            setReferralInfo(referrerInfo);
            setReferrals((prevReferrals) => {
                if (prevReferrals && page > 1) {
                    return {
                        ...referralsInfo,
                        result: [...prevReferrals.result, ...referralsInfo.result],
                    };
                }
                return referralsInfo;
            });
            setHasMore(referralsInfo.result.length === PAGE_SIZE);
        } catch (error) {
            handleError(error as AxiosError);
        } finally {
            setLoading(false);
        }

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        updateInfo();
    }, [updateInfo]);

    const loadMoreReferrals = useCallback(() => {
        if (!loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [loading, hasMore]);

    const renderContent = useMemo(() => {
        if (haveFriends) {
            return (
                <RefList
                    referralInfo={referralInfo}
                    referrals={referrals}
                    loading={loading}
                    hasMore={hasMore}
                    onLoadMore={loadMoreReferrals}
                    onClaim={onClaim}
                />
            );
        }

        return (
            <div className="flex bg-[#1A1A1A] border border-[#2B2B2B] p-4 rounded-2xl flex-col gap-6 mt-6">
                <div className="flex flex-col gap-6 pl-3">
                    <div className="flex flex-col gap-1 relative">
                        <div className="absolute left-[-10px] top-[8px] bottom-[-30px] w-0.5 bg-[#3EDC03]" />
                        <div className="absolute left-[-16px] top-[4px] w-3 h-3 rounded-full bg-[#3EDC03] flex items-center justify-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-[#1A1A1A]" />
                        </div>
                        <span className="pl-3 font-mono text-base font-bold text-[#E6E6E6]">
                            Share your referral link
                        </span>
                        <span className="pl-3 font-mono text-sm text-[#B3B3B3]">
                            Power-up your farming.
                        </span>
                    </div>
                    <div className="flex flex-col gap-1 relative">
                        <div className="absolute left-[-10px] top-[8px] bottom-[-30px] w-0.5 bg-[#3EDC03]" />
                        <div className="absolute left-[-16px] top-[4px] w-3 h-3 rounded-full bg-[#3EDC03] flex items-center justify-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-[#1A1A1A]" />
                        </div>
                        <span className="pl-3 font-mono text-base font-bold text-[#E6E6E6]">
                            Friends become coaches
                        </span>
                        <span className="pl-3 font-mono text-sm text-[#B3B3B3]">
                            And farm $MTGN for you too.
                        </span>
                    </div>
                    <div className="flex flex-col gap-1 relative">
                        <div className="absolute left-[-16px] top-[4px] w-3 h-3 rounded-full bg-[#3EDC03] flex items-center justify-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-[#1A1A1A]" />
                        </div>
                        <span className="pl-3 font-mono text-base font-bold text-[#E6E6E6]">
                            Earn 10% from your friends' farming
                        </span>
                        <span className="pl-3 font-mono text-sm text-[#B3B3B3]">
                            Plus an additional 2.5% from their friends.
                        </span>
                    </div>
                </div>
            </div>
        );
    }, [haveFriends, referralInfo, referrals, loading, hasMore, loadMoreReferrals, onClaim]);

    return (
        <>
            <div className="flex flex-col relative  justify-between pt-5 px-4 w-full h-full">
                {referralInfo && (
                    <>
                        <div className="flex flex-col h-full">
                            <span className="font-druk font-bold text-3xl text-white">
                                Invite Friends
                            </span>
                            {renderContent}
                        </div>
                        <div className="flex absolute bottom-7 right-0 left-0 w-full px-4">
                            <Button
                                className="py-3 font-mono font-semibold text-[18px] leading-[25px] h-auto !text-black"
                                type="primary"
                                block
                                onClick={() => setShowModal(true)}
                            >
                                Invite a friend
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {showModal && (
                <div className="fixed flex top-0 left-0 right-0 bottom-0">
                    <div className="flex h-full w-full">
                        <div
                            onClick={() => setShowModal(false)}
                            className="flex absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,_0,_0,_0.55)]"
                        ></div>
                        <div className="flex left-0 right-0 bottom-0 absolute z-[2] flex-col rounded-t-[12px] bg-[#060405] border-t-[2px] border-[rgba(69,_255,_0,_0.65)]">
                            <div className="flex w-full py-2.5 px-3 justify-center border-[#2B2B2B] border-b-[1px]">
                                <span className="text-[18px] font-mono text-[#E6E6E6]">
                                    Invite Friends
                                </span>
                            </div>
                            <div className="flex w-full py-7 px-4 gap-4 flex-col">
                                <Button
                                    className="py-3 font-mono font-semibold text-[18px] leading-[25px] h-auto !text-black"
                                    type="primary"
                                    block
                                    onClick={() => {
                                        const link = `${process.env.REACT_APP_INVITE_URL}?startapp=${user?.refCode}`;
                                        const text = `Activate your 🦠 now!`;
                                        const share = `https://t.me/share/url?url=${link}&text=${text}`;
                                        utils.openTelegramLink(share);
                                    }}
                                >
                                    Send
                                </Button>
                                <Button
                                    className="py-3 font-mono font-semibold text-[18px] leading-[25px] h-auto"
                                    type="default"
                                    block
                                    onClick={() => {
                                        const link = `${process.env.REACT_APP_INVITE_URL}?startapp=${user?.refCode}`;
                                        clipboard.writeText(link);
                                        toast('Link copied', {
                                            hideProgressBar: true,
                                            type: 'success',
                                            icon: false,
                                            autoClose: 3000,
                                            theme: 'dark',
                                            position: 'top-center',
                                        });
                                        setShowModal(false);
                                    }}
                                >
                                    Copy link
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
