import React, { useEffect, useRef } from 'react';
import { ProgressBar } from './ProgressBar';
import { Button } from 'antd';

type Props = {
    onNextScreen: VoidFunction;
};

const coeffs = {
    first: 20,
    second: 22,
    third: 24,
};

export const CreateAccountScreen: React.FC<Props> = ({ onNextScreen }) => {
    const [progresses, setProgresses] = React.useState({
        first: 0,
        second: 0,
        third: 0,
    });

    const showNext =
        progresses.first === 100 && progresses.second === 100 && progresses.third === 100;

    const tm = useRef<NodeJS.Timeout | null>();

    const updateLoading = () => {
        tm.current = setTimeout(() => {
            setProgresses((prev) => ({
                first: Math.ceil(Math.min(prev.first + coeffs.first * Math.random(), 100)),
                second: Math.ceil(Math.min(prev.second + coeffs.second * Math.random(), 100)),
                third: Math.ceil(Math.min(prev.third + coeffs.third * Math.random(), 100)),
            }));

            updateLoading();
        }, 700);
    };

    useEffect(() => {
        tm.current && clearTimeout(tm.current);

        updateLoading();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (progresses.first === 100 && progresses.second === 100 && progresses.third === 100) {
            if (tm.current) {
                clearTimeout(tm.current);
            }
        }

        // eslint-disable-next-line
    }, [progresses]);

    return (
        <div className="flex flex-col w-full h-full gap-4 p-4">
            <div className="flex flex-col w-full">
                <div className="flex flex-col gap-3 pt-5">
                    <p className="font-druk text-left text-white text-2xl font-bold leading-7">
                        Setting up new
                    </p>
                    <p className="font-druk text-left text-white text-2xl font-bold leading-7">
                        account
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🦠 Activating Roach incubation
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.first} />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🧬 Calculating unique Roach DNA
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.second} />
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-mono text-base text-[#E6E6E6]">
                        🧪 Activating $MTGN farming
                    </span>
                    <div className="">
                        <ProgressBar progress={progresses.third} />
                    </div>
                </div>
            </div>
            {showNext && (
                <div className="mt-auto flex w-full pb-3">
                    <Button
                        type="primary"
                        className="py-3 font-mono font-semibold text-[18px] leading-[25px] h-auto !text-black disabled:!text-white w-full"
                        onClick={onNextScreen}
                    >
                        Activate
                    </Button>
                </div>
            )}
        </div>
    );
};
