import { useCountdown } from '../../../../hooks/useCountdown';
import React, { memo } from 'react';

type Props = {
    endTime?: string;
    serverTimeDiffMs?: number;
};

export const Countdown: React.FC<Props> = memo(({ endTime, serverTimeDiffMs }) => {
    const countdown = useCountdown(endTime, serverTimeDiffMs);

    if (!countdown) {
        return null;
    }

    return (
        <span className={'countdown font-mono text-xs text-center'}>
            {countdown.isEnded
                ? 'Finished'
                : `${countdown.hours.toString().padStart(2, '0')}:${countdown.minutes.toString().padStart(2, '0')}:${countdown.seconds.toString().padStart(2, '0')}`
            }
        </span>
    );
});
