import React, { memo } from 'react';

type Props = {
    isPremium: boolean;
};

export const SvgCircle: React.FC<Props> = memo(({ isPremium }) => {
    if (!isPremium) {
        return (
            <svg
                width="328"
                height="328"
                viewBox="0 0 328 328"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_3174_15529)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M312.839 163H312.997C312.459 81.1698 245.957 15 164.001 15C82.0438 15 15.5417 81.1698 15.0039 163H15.1621C18.8176 83.9596 84.0566 21 164.001 21C243.945 21 309.184 83.9596 312.839 163Z"
                        fill="url(#paint0_linear_3174_15529)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M312.839 163H312.997C312.459 81.1698 245.957 15 164.001 15C82.0438 15 15.5417 81.1698 15.0039 163H15.1621C18.8176 83.9596 84.0566 21 164.001 21C243.945 21 309.184 83.9596 312.839 163Z"
                        fill="black"
                        fillOpacity="0.6"
                    />
                    <circle
                        cx="164"
                        cy="164"
                        r="156"
                        stroke="url(#paint1_linear_3174_15529)"
                        strokeWidth="16"
                    />
                    <circle
                        cx="164"
                        cy="164"
                        r="163"
                        stroke="url(#paint2_linear_3174_15529)"
                        strokeWidth="2"
                    />
                    <circle
                        cx="164"
                        cy="164"
                        r="163"
                        stroke="url(#paint3_linear_3174_15529)"
                        strokeWidth="2"
                    />
                    <circle cx="164" cy="164" r="162.5" stroke="url(#paint4_linear_3174_15529)" />
                    <circle cx="164" cy="164" r="162.5" stroke="url(#paint5_linear_3174_15529)" />
                    <circle
                        cx="164"
                        cy="164"
                        r="148"
                        stroke="url(#paint6_linear_3174_15529)"
                        strokeWidth="2"
                    />
                    <circle
                        cx="164"
                        cy="164"
                        r="148"
                        stroke="url(#paint7_linear_3174_15529)"
                        strokeWidth="2"
                    />
                    <circle cx="164" cy="164" r="148.5" stroke="url(#paint8_linear_3174_15529)" />
                    <circle cx="164" cy="164" r="148.5" stroke="url(#paint9_linear_3174_15529)" />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_3174_15529"
                        x1="160.821"
                        y1="167.738"
                        x2="160.821"
                        y2="8.45733"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_3174_15529"
                        x1="160.5"
                        y1="338.5"
                        x2="160.5"
                        y2="-14.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_3174_15529"
                        x1="160.5"
                        y1="338.5"
                        x2="160.5"
                        y2="-14.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_3174_15529"
                        x1="164"
                        y1="0"
                        x2="164"
                        y2="328"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopOpacity="0" />
                        <stop offset="1" stopOpacity="0.3" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_3174_15529"
                        x1="160.521"
                        y1="337.436"
                        x2="160.521"
                        y2="-13.4116"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_3174_15529"
                        x1="164"
                        y1="1"
                        x2="164"
                        y2="327"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopOpacity="0" />
                        <stop offset="1" stopOpacity="0.35" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_3174_15529"
                        x1="160.82"
                        y1="322.54"
                        x2="160.82"
                        y2="1.82624"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_3174_15529"
                        x1="164"
                        y1="15"
                        x2="164"
                        y2="313"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopOpacity="0" />
                        <stop offset="1" stopOpacity="0.4" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_3174_15529"
                        x1="160.82"
                        y1="322.54"
                        x2="160.82"
                        y2="1.82624"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#A8A8A6" />
                        <stop offset="0.101046" stopColor="#696969" />
                        <stop offset="0.451645" stopColor="#696969" />
                        <stop offset="0.665" stopColor="#F9F8F6" />
                        <stop offset="0.807368" stopColor="#D4D4D4" />
                        <stop offset="1" stopColor="#7F7F7F" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_3174_15529"
                        x1="164"
                        y1="15"
                        x2="164"
                        y2="313"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopOpacity="0" />
                        <stop offset="1" stopOpacity="0.35" />
                    </linearGradient>
                    <clipPath id="clip0_3174_15529">
                        <rect width="328" height="328" rx="164" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    return (
        <svg
            width="328"
            height="328"
            viewBox="0 0 328 328"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2907_19226)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M312.819 163H312.997C312.459 81.1698 245.957 15 164.001 15C82.0438 15 15.5417 81.1698 15.0039 163H15.1827C19.0551 84.162 84.2021 21.4314 164.001 21.4314C243.799 21.4314 308.946 84.162 312.819 163Z"
                    fill="black"
                />
                <circle
                    cx="164"
                    cy="164"
                    r="156"
                    stroke="url(#paint0_linear_2907_19226)"
                    strokeWidth="16"
                />
                <circle
                    cx="164"
                    cy="164"
                    r="163"
                    stroke="url(#paint1_linear_2907_19226)"
                    strokeWidth="2"
                />
                <circle cx="164" cy="164" r="162.5" stroke="url(#paint2_linear_2907_19226)" />
                <circle
                    cx="164"
                    cy="164"
                    r="148"
                    stroke="url(#paint3_linear_2907_19226)"
                    strokeWidth="2"
                />
                <circle cx="164" cy="164" r="148.5" stroke="url(#paint4_linear_2907_19226)" />
                <g filter="url(#filter0_f_2907_19226)">
                    <circle
                        cx="163"
                        cy="328"
                        r="164"
                        fill="url(#paint5_linear_2907_19226)"
                        fillOpacity="0.5"
                    />
                </g>
                <g filter="url(#filter1_f_2907_19226)">
                    <circle cx="164" cy="328" r="64" fill="#002100" />
                </g>
                <g filter="url(#filter2_f_2907_19226)">
                    <circle cx="164" cy="328" r="16" fill="black" fillOpacity="0.9" />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_f_2907_19226"
                    x="-201"
                    y="-36"
                    width="728"
                    height="728"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_2907_19226" />
                </filter>
                <filter
                    id="filter1_f_2907_19226"
                    x="-20"
                    y="144"
                    width="368"
                    height="368"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="60" result="effect1_foregroundBlur_2907_19226" />
                </filter>
                <filter
                    id="filter2_f_2907_19226"
                    x="92"
                    y="256"
                    width="144"
                    height="144"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="28" result="effect1_foregroundBlur_2907_19226" />
                </filter>
                <linearGradient
                    id="paint0_linear_2907_19226"
                    x1="164"
                    y1="0"
                    x2="164"
                    y2="328"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#373737" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2907_19226"
                    x1="164"
                    y1="0"
                    x2="164"
                    y2="328"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3D3D3D" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2907_19226"
                    x1="164"
                    y1="1"
                    x2="164"
                    y2="327"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#252525" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2907_19226"
                    x1="164"
                    y1="15"
                    x2="164"
                    y2="313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#080808" />
                    <stop offset="1" stopColor="#2E2E2E" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2907_19226"
                    x1="164"
                    y1="15"
                    x2="164"
                    y2="313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#090909" />
                    <stop offset="1" stopColor="#232323" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2907_19226"
                    x1="163"
                    y1="164"
                    x2="163"
                    y2="492"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#32B900" stopOpacity="0.6" />
                    <stop offset="0.295" stopOpacity="0.6" />
                </linearGradient>
                <clipPath id="clip0_2907_19226">
                    <rect width="328" height="328" rx="164" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});

SvgCircle.displayName = 'SvgCircle';
